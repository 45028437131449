// src/components/Footer.js
import React from 'react';
import './Footer.css';

const Footer = () => {
    return (
        <footer className="footer mt-auto py-4">
            <div className="container text-center">
                {/* <h5>Follow Us</h5>
                <div className="social-icons">
                    <a href="https://facebook.com" target="_blank" rel="noreferrer">Facebook</a> | 
                    <a href="https://twitter.com" target="_blank" rel="noreferrer">Twitter</a> | 
                    <a href="https://linkedin.com" target="_blank" rel="noreferrer">LinkedIn</a>
                </div> */}
                <p className="mt-3">&copy; {new Date().getFullYear()} TechnoSpire. All Rights Reserved.</p>
            </div>
        </footer>
    );
};

export default Footer;
