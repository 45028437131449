import React, { useEffect, useRef } from 'react';
import { gsap } from 'gsap';
import { ScrollTrigger } from 'gsap/ScrollTrigger';
import './About.css';
import Navigation from '../common/navbar/Navigation';
import Footer from '../common/footer/Footer';
import { useGSAP } from '@gsap/react';
import { ScrollTop } from 'primereact/scrolltop';

gsap.registerPlugin(ScrollTrigger);

const About = () => {
    const introRef = useRef(null);
    const journeyRef = useRef(null);
    const teamRef = useRef(null);
    const visionRef = useRef(null);
    const imageRef = useRef([]);
    const stringRef = useRef([]);
    const visionImageRef = useRef();
    const visionContentRef = useRef([]);

    useEffect(() => {
        const scrollTimeout = setTimeout(() => {
            window.scrollTo(0, 0);
        }, 0);
    
        return () => clearTimeout(scrollTimeout); // Cleanup timeout
    }, []);
    

    useGSAP(() => {

        const sections = [introRef, journeyRef, teamRef];

        sections.forEach((section) => {
            gsap.fromTo(
                section.current,
                { opacity: 0, y: 50 },
                {
                    opacity: 1,
                    y: 0,
                    duration: 2,
                    ease: 'power3.out',
                    scrollTrigger: {
                        trigger: section.current,
                        start: 'top 70%',
                        end: 'bottom 60%',
                        scrub: true,
                    },
                }
            );
        });

        imageRef.current.forEach((imageRef) => {
            gsap.fromTo(
                imageRef,
                { opacity: 0, x: 50 },
                {
                    opacity: 1,
                    x: 0,
                    duration: 2,
                    ease: 'power3.out',
                    scrollTrigger: {
                        trigger: imageRef,
                        start: 'top 70%',
                        end: 'bottom 60%',
                        scrub: true,
                    },
                }
            )
        });


        const screenWidth = window.innerWidth;
        const offsetX = -1.5 * screenWidth;
        const initialPath = `M 10 100 Q 100 100 200 100`;


        gsap.to('#vision-title-section h2', {
            x: "-120%",
            duration: 0.5,
            delay:1,
            ease: "power2.out",
            scrollTrigger: {
                trigger: "#vision-title-section",
                scroller: "body",
                // markers: true,
                start: "top 0%",
                end: `top -100%`,
                scrub: 1,
                pin: true,
                // pinSpacing: true
            }
        });

        const stringElement = stringRef.current;

        stringElement.addEventListener('mousemove', (e) => {
            // Get the bounding box of the container
            const rect = stringElement.getBoundingClientRect();

            // Calculate x and y relative to the box
            const x = e.clientX - rect.left; // Mouse x within the box
            const y = e.clientY - rect.top;  // Mouse y within the box
            const finalPath = `M 10 100 Q 100 ${y} 200 100`;
            // console.log(e.x);
            gsap.to("#string svg path", {
                attr: {
                    d: finalPath
                },
                ease: "power3.out",
                duration: 0.3
            })
        })

        stringElement.addEventListener('mouseleave', () => {
            gsap.to("#string svg path", {
                attr: {
                    d: initialPath
                },
                duration: 1.5,
                ease: "elastic.out(1,0.1)",
            })
        })

        gsap.from(visionImageRef.current, {
            x: "100%",
            y: "200",
            duration: 0.5,
            ease: "power2.out",
            ease: "none",
            opacity: 0,
            scrollTrigger: {
                trigger: ".vision-section",
                scroller: "body",
                // markers: true,
                // start: "top 0%",
                end: `top 40%`,
                scrub: 1,
                // pinSpacing: true
            }
        })

        console.log(visionContentRef);
        gsap.from(visionContentRef.current, {
            x: "-100%",
            y: "200",
            duration: 1,
            // rotate:180,
            // ease: "power1.out",
            opacity: 0,
            stagger: 1,
            scrollTrigger: {
                trigger: ".vision-section p p",
                scroller: "body",
                // markers: true,
                start: 'top 80%',
                        end: 'bottom 60%',
                        toggleActions: 'play none none reverse',
                scrub: 1,
                // pinSpacing: true
            }
        })

    })

    const setImageRef = (ref, index) => {
        imageRef.current[index] = ref;
    };

    const setVisionContent = (ref, index) => {
        visionContentRef.current[index] = ref;
    };

    return (
        <>
            <Navigation />
            <div className="about-page">
                {/* Introduction Section */}
                <section ref={introRef} className="about-section">
                    <img src="img/about1.webp" ref={(ref) => setImageRef(ref, 0)} alt="Empowering Businesses" className="about-image" />
                    <h2>About TechnoSpire</h2>
                    <p>
                        At TechnoSpire, our mission is to empower businesses with state-of-the-art technology.
                        We believe in a customer-centric approach and strive to provide innovative solutions
                        that help companies reach new heights.
                    </p>
                </section>

                {/* Our Journey Section */}
                <section ref={journeyRef} className="about-section">
                    <img src="img/about2.webp" ref={(ref) => setImageRef(ref, 1)} alt="Our Journey" className="about-image" />
                    <h2>Our Journey</h2>
                    <p>
                        TechnoSpire began as a small startup with a big dream. Over the years, we have grown into
                        a leader in SaaS, API integration, and DevOps, marked by milestones that showcase our
                        commitment to quality and innovation.
                    </p>
                </section>

                {/* Meet Our Team Section */}
                <section ref={teamRef} className="about-section">
                    <img src="img/about3.webp" alt="Our Team" ref={(ref) => setImageRef(ref, 2)} className="about-image" />
                    <h2>Meet Our Team</h2>
                    <p>
                        Our team is the heart of TechnoSpire. With experts in software development, DevOps,
                        and cloud solutions, we bring together a wealth of experience and a passion for
                        technology. Each member contributes unique skills that drive our success.
                    </p>
                </section>

                <div ref={stringRef} id="string">
                    <svg width="100%" height="200" viewBox="0 0 200 200" preserveAspectRatio="none">
                        {/* Adjust the y-values to make the line appear centered at 100px */}
                        <path d="M 10 100 Q 100 100 200 100" stroke="black" fill="transparent" />
                    </svg>
                </div>


                {/* Our Approach and Vision Section */}
                <div id="vision-title-section">
                    <h2>Our Vision</h2>
                </div>
                <div className="vision-section">

                    <p>
                        <img
                            src="img/about.webp"
                            ref={visionImageRef}
                            alt="Future Vision"
                            className="about-image-right"
                        />
                        <p ref={(ref) => { setVisionContent(ref, 0) }}>At TechnoSpire, we envision a future where cutting-edge technology is seamlessly integrated into every business, empowering organizations of all sizes to operate with efficiency, agility, and innovation. As we look ahead, we aim to be at the forefront of advancements in artificial intelligence, machine learning, and cloud technologies, redefining what’s possible in the modern business landscape.</p>


                        <p ref={(ref) => { setVisionContent(ref, 1) }}>In a rapidly transforming world, businesses must adapt to changing environments, evolving consumer expectations, and the dynamic demands of the digital economy. We believe that AI and machine learning offer unprecedented opportunities for businesses to innovate and grow. These technologies can provide insightful data analysis, predict trends, and automate routine processes, allowing businesses to focus on strategic decision-making and core activities.</p>

                        <p ref={(ref) => { setVisionContent(ref, 2) }}>As cloud technology continues to shape the digital landscape, TechnoSpire is committed to offering secure, scalable, and flexible cloud solutions that enable businesses to operate without the limitations of traditional infrastructure. We envision a world where companies can effortlessly expand their capabilities, enhance collaboration, and gain resilience against uncertainties by leveraging the cloud.</p>
                        <p ref={(ref) => { setVisionContent(ref, 3) }}>TechnoSpire’s vision is rooted in a belief that every business deserves the tools to succeed and flourish in a competitive world. By leading the way in AI, machine learning, and cloud technology, we are building a future where businesses are not only more technologically equipped but also more capable of creating value for their customers and communities. Our mission is to be a trusted partner in this journey, supporting businesses in their evolution toward a smarter, more connected future.</p>
                        <p ref={(ref) => { setVisionContent(ref, 4) }}>We see ourselves as pioneers in a tech-driven world, continually evolving to meet the demands of tomorrow. TechnoSpire is dedicated to reshaping industries, unlocking potential, and driving meaningful impact—one innovative solution at a time. Together with our clients, we are building a future where technology is a powerful enabler, transforming possibilities into reality and helping every business thrive.</p>
                    </p>

                </div>
            </div >
            <Footer />
            <ScrollTop className='scroll-top' />
        </>
    );
};

export default About;
