import React, { useEffect, useRef, useState } from 'react';
import { gsap } from 'gsap';
import { ScrollTrigger } from 'gsap/ScrollTrigger';
import 'bootstrap/dist/css/bootstrap.min.css';
import './Home.css';
import Footer from '../common/footer/Footer';
import Navigation from '../common/navbar/Navigation';
import { Helmet } from 'react-helmet';
import { useGSAP } from '@gsap/react';
import { ScrollTop } from 'primereact/scrolltop';
import LocomotiveScroll from 'locomotive-scroll';
import Loader from '../GenericModel/Loader/Loader';

const scroll = new LocomotiveScroll();

gsap.registerPlugin(ScrollTrigger);

const Home = () => {
    const heroRef = useRef(null);
    const featuresRef = useRef(null);
    const servicesRef = useRef(null);
    const containerRef = useRef(null);
    const containersRef = useRef([]);
    const [loading, setLoading] = useState(false);
    const images = [];
    const frameCount = 240; // Adjust as needed
    let loadedImages = 0;

    const loadImages = () => { debugger
        setLoading(true);
        for (let i = 0; i < frameCount; i++) { debugger
          const img = new Image();
          img.src = `/img/cpu/Image${i + 1}.webp`;
          img.onload = () => {
            loadedImages++;
            if (loadedImages === frameCount) {
              setLoading(false); // All images loaded
            }
          };
          images.push(img);
        }
      };


    useEffect(() => {
        loadImages();

        // Simulate additional loading for other assets (optional)
        const loadTimeout = setTimeout(() => {
            setLoading(false);
        }, 3000); // Adjust delay as needed

        return () => clearTimeout(loadTimeout);
    }, []);


    useEffect(() => {
        const canvas = document.querySelector("#home>canvas");
        const context = canvas.getContext("2d");

        canvas.width = window.innerWidth;
        canvas.height = window.innerHeight;

        const imageSeq = { frame: 0 };

        // GSAP animation
        gsap.to(imageSeq, {
            frame: frameCount - 1,
            snap: "frame",
            ease: "none",
            scrollTrigger: {
                scrub: 1.5,
                pin: true,
                trigger: "#home",
                start: "top top",
                end: "bottom top",
            },
            onUpdate: render,
        });

        images[0].onload = render;

        function render() {
            requestAnimationFrame(() => scaleImage(images[imageSeq.frame], context));
        }
        

        function scaleImage(img, ctx) {
            const canvas = ctx.canvas;
            const hRatio = canvas.width / img.width;
            const vRatio = canvas.height / img.height;
            const ratio = Math.max(hRatio, vRatio);
            const centerShift_x = (canvas.width - img.width * ratio) / 2;
            const centerShift_y = (canvas.height - img.height * ratio) / 2;
            ctx.clearRect(0, 0, canvas.width, canvas.height);
            ctx.drawImage(img, 0, 0, img.width, img.height, centerShift_x, centerShift_y, img.width * ratio, img.height * ratio);
        }

        const handleResize = () => {
            canvas.width = window.innerWidth;
            canvas.height = window.innerHeight;
            render();
        };

        gsap.to("#home canvas", {
            scale: 0.8,
            scrollTrigger: {
                scrub: .1,
                trigger: "#home",
                start: "bottom 100%",
                // markers:true,
            },
        })

       
        window.addEventListener("resize", handleResize);

        return () => {
            ScrollTrigger.getAll().forEach(trigger => trigger.kill());
            window.removeEventListener("resize", handleResize);
        };
    }, []);


    useEffect(() => {
        const scrollTimeout = setTimeout(() => {
            window.scrollTo(0, 0);
        }, 0);

        return () => clearTimeout(scrollTimeout); // Cleanup timeout
    }, []);


    useGSAP(() => {
        // Hero section animation on load
        gsap.to("#welcome-container h1", {
            y: "-200%",
            scale: 10,
            fontSize: 1000,
            duration: 0.5,
            delay: 0.2,
            opacity: 0.8,
            scrollTrigger: {
                trigger: "#welcome-container",
                start: "top 0%",
                end: "bottom",
                scrub: 1,
                pin: true,
                pinSpacing: true,
            },
        });

        // Animation for hero section content fading in
        gsap.fromTo(
            heroRef.current.querySelectorAll("h1, p, button"),
            { opacity: 0, y: 50, scale: 0.8 },
            {
                opacity: 1,
                y: 0,
                scale: 1,
                duration: 1,
                delay: 0.5,
                stagger: 0.3,
                scrollTrigger: {
                    scrub: 1,
                    trigger: heroRef.current,
                    start: "top 50%", // Start fading in as the welcome animation ends
                    end: "top 30%"
                },
            }
        );



        // Mouse move animation
        const handleMouseMove = (e) => {
            // console.log(e.x)
            gsap.to("#cursor", {
                x: e.x - 7,
                y: e.y - 7,
                duration: 0.2,
                // delay: 0.2,
                ease: "elastic.out(1,0.3)",
            });
        };

        window.addEventListener('mousemove', handleMouseMove);

        // Cleanup event listener on component unmount
        return () => {
            window.removeEventListener('mousemove', handleMouseMove);
        };
    }, []);

    return (
        <>
            <Helmet>
                <meta property="og:image" content={`https://TechnoSpire.org.in/og-image.png`} />
                <meta property="og:image:alt" content="TechnoSpire software solutions" />
                <meta property="og:image:width" content="1200" />  {/* Replace with your actual image width */}
                <meta property="og:image:height" content="630" />
            </Helmet>
            <Navigation />
            {loading ? <Loader show={loading}/> : ""}
            <div className="container">
                <div className="main-welcom-container">
                    <div className="welcom-bg"></div>
                    <div id="welcome-container">
                        <h1>Welcome</h1>
                    </div>
                </div>
                {/* <div id="cursor"></div>7y */}
                {/* Hero Section */}
                <section ref={heroRef} className="hero-section section">
                    <h1>Welcome to TechnoSpire</h1>
                    <p>Empowering businesses with innovative software solutions and cutting-edge technology.</p>
                    <button className="btn">Discover More</button>
                </section>

                {/* Features Section */}
                <section className="features-section" ref={containerRef} data-scroll-container>
                    <div id="home">
                        <canvas></canvas>
                    </div>

                    <div className="txt-home">
                        <div className="text-center features-text">
                            <h3>Expert Development</h3>
                            <p>Our expert team specializes in developing reliable, high-quality software solutions tailored to your specific needs. With a focus on innovation and precision, we deliver customized products that drive success, ensuring seamless performance and exceptional results for your business. Let us transform your vision into reality!</p>
                        </div>
                        <div className="text-center features-text">
                            <h3>Scalable Solutions</h3>
                            <p>We create scalable, future-ready platforms designed to grow alongside your business. Our solutions ensure seamless adaptability, empowering your enterprise to thrive and achieve long-term success in an ever-evolving market.</p>
                        </div>
                        <div className="text-center features-text">
                            <h3>Client-Centric Approach</h3>
                            <p>Our client-centric approach places your needs at the forefront, ensuring exceptional service and tailored solutions. By focusing on understanding your goals, we deliver unmatched results that exceed expectations, fostering long-term partnerships built on trust, reliability, and success. Your satisfaction is our ultimate priority.</p>
                        </div>
                    </div>
                </section>

                {/* Services Section */}
                <section className="services-section section h-100">
                    <h2>Our Services</h2>
                    <p className="ps-3 pe-3">At TechnoSpire, we deliver a comprehensive range of IT services to meet your business's technology needs. Our expertise spans from modern web and mobile app development to advanced cloud solutions and API integrations. Whether you're seeking custom software, secure IT infrastructure, or data-driven AI solutions, we have you covered.</p>

                        <div className="technologys" ref={(el) => (containersRef.current[0] = el)}>
                            <div className='tech-img'>
                                <img src="./img/service/web.webp" alt="" />
                            </div>
                            <div className="tech-detail p-3">
                                <h4>Web Development</h4>
                                <p>At Technospire, we create responsive, user-centric websites tailored to meet your business needs. Using advanced technologies like React, Angular, and Vue.js, we deliver sleek designs that ensure seamless functionality across devices. Our websites focus on intuitive navigation and exceptional user experience, helping your brand stand out while driving engagement and results in today’s competitive digital world.</p>
                                <p>Our web development services combine innovation and technical expertise to craft websites that truly impact your business. From modern front-end interfaces to secure and scalable back-end systems, we handle it all. We also offer ongoing maintenance to keep your site optimized and updated. With Technospire, you gain a dynamic digital presence that effectively represents your brand and drives growth.</p>
                            </div>
                        </div>
                        <div className="technologys" ref={(el) => (containersRef.current[1] = el)}>
                            <div className='tech-img'>
                                <img src="./img/service/appdevlopment.webp" alt="" />
                            </div>
                            <div className="tech-detail p-3">
                                <h4>Mobile App Development</h4>
                                <p>Bring your ideas to life with feature-rich mobile applications that captivate and engage users. Our mobile app development services prioritize smooth performance, intuitive navigation, and exceptional user experiences. Whether you’re launching a new concept or expanding your business, we design apps that leave a lasting impression and build lasting connections.</p>
                                <p>Every app we create is tailored to meet specific objectives, ensuring it aligns with your business vision. By focusing on user needs and market trends, we deliver solutions that exceed expectations. From concept to deployment, we turn your vision into an app that drives success.</p>
                            </div>
                        </div>
                        <div className="technologys" ref={(el) => (containersRef.current[2] = el)}>
                            <div className='tech-img'>
                                <img src="./img/service/api.webp" alt="" />
                            </div>
                            <div className="tech-detail p-3">
                                <h4>API Development & Integration</h4>
                                <p>Seamless connectivity is the backbone of modern applications. Technospire provides robust API development and integration services to ensure smooth communication between your systems and external platforms. Our solutions simplify complex processes, enabling your applications to perform efficiently and adapt to changing requirements.</p>
                                <p>By prioritizing reliability and scalability, we help businesses streamline operations and improve productivity. Whether creating custom APIs or integrating third-party services, we deliver secure and efficient solutions designed to enhance your business ecosystem and enable seamless collaboration.</p>
                            </div>
                        </div>
                        <div className="technologys" ref={(el) => (containersRef.current[3] = el)}>
                            <div className='tech-img'>
                                <img src="./img/service/cloud-solutions.webp" alt="" />
                            </div>
                            <div className="tech-detail p-3">
                                <h4>Cloud Solutions</h4>
                                <p>The cloud is no longer just a convenience—it’s a necessity for modern businesses. Our cloud solutions are designed to provide secure migration, reliable storage, and flexible computing capabilities. Whether you're scaling operations or improving collaboration, we help you harness the power of the cloud to drive efficiency and innovation while ensuring your data remains protected.</p>
                                <p>By prioritizing scalability and security, we offer tailored solutions that adapt to your growing needs. From transitioning legacy systems to the cloud to optimizing your existing infrastructure, we ensure seamless integration and peace of mind. Let us help you unlock the full potential of cloud computing to future-proof your business.</p>
                            </div>
                        </div>
                        <div className="technologys" ref={(el) => (containersRef.current[4] = el)}>
                            <div className='tech-img'>
                                <img src="./img/service/custom-software.webp" alt="" />
                            </div>
                            <div className="tech-detail p-3">
                                <h4>Custom Software Development</h4>
                                <p>Every business is unique, and so are its software needs. We specialize in creating custom software applications that are built around your specific goals and processes. By aligning our expertise with your requirements, we deliver scalable, user-friendly solutions that enhance productivity and streamline operations, helping you stay ahead in a competitive market.</p>
                                <p>Our development process focuses on delivering quality and reliability. From ideation to deployment, we ensure your software integrates seamlessly with existing systems while providing room for future growth. Trust us to build applications that not only solve your challenges but also empower your business to achieve more.</p>
                            </div>
                        </div>
                        <div className="technologys" ref={(el) => (containersRef.current[5] = el)}>
                            <div className='tech-img'>
                                <img src="./img/service/it-cu.webp" alt="" />
                            </div>
                            <div className="tech-detail p-3">
                                <h4>IT Consulting</h4>
                                <p>Navigating the ever-evolving IT landscape can be challenging, but you don’t have to do it alone. Our IT consulting services provide the expertise and guidance needed to align your technology strategies with your business objectives. From assessing current systems to identifying new opportunities, we help you optimize resources and achieve measurable results.</p>
                                <p>By focusing on innovative yet practical solutions, we ensure your IT infrastructure supports your growth. Whether you need to enhance efficiency, implement new technologies, or future-proof your operations, we deliver tailored strategies that drive success. Partner with us to transform IT into a competitive advantage.</p>
                            </div>
                        </div>
                        <div className="technologys" ref={(el) => (containersRef.current[6] = el)}>
                            <div className='tech-img'>
                                <img src="./img/service/ai-data.webp" alt="" />
                            </div>
                            <div className="tech-detail p-3">
                                <h4>Data Analytics & Artificial Intelligence</h4>
                                <p>Data is the backbone of informed decision-making, and we help you unlock its full potential. Through advanced analytics and machine learning models, we provide actionable insights that drive smarter strategies. Whether you’re analyzing customer behavior or optimizing business operations, our AI-powered solutions enable you to harness data effectively.</p>
                                <p>With AI and predictive analytics at the core, we empower your business to stay ahead of industry trends. From automating routine tasks to uncovering hidden opportunities, we help you use data as a strategic asset. Make better decisions, improve performance, and achieve sustainable growth with our data-driven solutions.</p>
                            </div>
                        </div>
                        <div className="technologys" ref={(el) => (containersRef.current[7] = el)}>
                            <div className='tech-img'>
                                <img src="./img/service/Cyber Security.webp" alt="" />
                            </div>
                            <div className="tech-detail p-3">
                                <h4>Cybersecurity Services</h4>
                                <p>In today’s digital age, cybersecurity is more than a necessity—it’s a responsibility. We provide comprehensive protection against evolving threats through services like penetration testing, data encryption, and network security. By proactively identifying vulnerabilities, we safeguard your business, ensuring your operations remain secure and uninterrupted.</p>
                                <p>Our approach goes beyond defense. We help you build a resilient security framework that adapts to emerging threats. From securing sensitive data to protecting your infrastructure, our solutions give you confidence in your digital environment. Stay one step ahead of cybercriminals and focus on growing your business with peace of mind.</p>
                            </div>
                        </div>
                        <div className="technologys" ref={(el) => (containersRef.current[8] = el)}>
                            <div className='tech-img'>
                                <img src="./img/service/ui-ux.webp" alt="" />
                            </div>
                            <div className="tech-detail p-3">
                                <h4>UI/UX Design</h4>
                                <p>Great design is about more than aesthetics; it’s about creating a seamless experience for your users. We specialize in crafting intuitive UI/UX designs that not only look stunning but also enhance usability. Every element we design is tailored to align with your brand identity, ensuring a cohesive and engaging experience across all devices and platforms.</p>
                                <p>Our approach focuses on the perfect balance between creativity and functionality. From wireframes to prototypes, we follow a user-centric design process that prioritizes ease of navigation and interaction. Whether it’s a website, mobile app, or software interface, our designs make every interaction meaningful, ensuring your users stay connected with your brand.</p>
                            </div>
                        </div>
                        <div className="technologys" ref={(el) => (containersRef.current[9] = el)}>
                            <div className='tech-img'>
                                <img src="./img/service/e-commerce.webp" alt="" />
                            </div>
                            <div className="tech-detail p-3">
                                <h4>E-commerce Development</h4>
                                <p>Transform your online store into a revenue-generating powerhouse with our e-commerce solutions. We create tailored platforms designed to provide seamless shopping experiences, ensuring your customers can browse, shop, and pay effortlessly. Our focus is on building scalable, secure, and feature-rich e-commerce systems that adapt to your growing business needs.</p>
                                <p>From intuitive product catalogs to efficient payment gateways, we ensure every component of your platform works harmoniously to enhance user satisfaction. Whether you’re launching a new store or revamping an existing one, our solutions are built to deliver optimal performance, drive conversions, and help your business thrive in the competitive e-commerce market.</p>
                            </div>
                        </div>
                        <div className="technologys" ref={(el) => (containersRef.current[10] = el)}>
                            <div className='tech-img'>
                                <img src="./img/service/devops.webp" alt="" />
                            </div>
                            <div className="tech-detail p-3">
                                <h4>DevOps Solutions</h4>
                                <p>Accelerate your software delivery process with our comprehensive DevOps services. We streamline development and deployment cycles by integrating agile practices, automation, and robust infrastructure management. Our goal is to help your teams collaborate efficiently, ensuring faster releases, improved quality, and reliable application performance.</p>
                                <p>By combining cutting-edge tools with proven methodologies, we build DevOps pipelines that enable continuous integration, delivery, and monitoring. Whether it’s automating workflows, optimizing cloud infrastructure, or enhancing system scalability, we provide solutions that keep your business agile and competitive. Let us help you achieve operational excellence through DevOps.</p>
                            </div>
                        </div>
                        {/* <div className="technologys" ref={(el) => (containersRef.current[0] = el)}>
                            <div className='tech-img'>
                                <img src="" alt="" />
                            </div>
                            <div className="tech-detail p-3">
                                <h4>IT Infrastructure Setup & Maintenance</h4>
                                <p>From network configuration to server management, we provide reliable IT infrastructure solutions to support your growing business needs.</p>
                            </div>
                        </div> */}
                </section>
            </div>
            <Footer />
            <ScrollTop className='scroll-top' />
        </>
    );
};

export default Home;
